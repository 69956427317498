require("core-js/modules/es.array.concat");

require("core-js/modules/es.array.map");

require("core-js/modules/es.function.name");

require("core-js/modules/es.number.constructor");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.replace");

require("core-js/modules/es.string.split");

(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "vant/es/toast/style", "vant/es/toast", "/root/deploy/iot-product-tool/node_modules/@babel/runtime/helpers/esm/defineProperty", "vant/es/action-sheet/style", "vant/es/action-sheet", "vant/es/icon/style", "vant/es/icon", "vant/es/image-preview/style", "vant/es/image-preview", "vant/es/uploader/style", "vant/es/uploader", "vant/es/field/style", "vant/es/field", "@/utils/time", "@/lib/compressImage"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("vant/es/toast/style"), require("vant/es/toast"), require("/root/deploy/iot-product-tool/node_modules/@babel/runtime/helpers/esm/defineProperty"), require("vant/es/action-sheet/style"), require("vant/es/action-sheet"), require("vant/es/icon/style"), require("vant/es/icon"), require("vant/es/image-preview/style"), require("vant/es/image-preview"), require("vant/es/uploader/style"), require("vant/es/uploader"), require("vant/es/field/style"), require("vant/es/field"), require("@/utils/time"), require("@/lib/compressImage"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.style, global.toast, global.defineProperty, global.style, global.actionSheet, global.style, global.icon, global.style, global.imagePreview, global.style, global.uploader, global.style, global.field, global.time, global.compressImage);
    global.addUploadImgPage = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _style, _toast, _defineProperty2, _style2, _actionSheet, _style3, _icon, _style4, _imagePreview, _style5, _uploader, _style6, _field, _time, _compressImage) {
  "use strict";

  var _interopRequireDefault = require("/root/deploy/iot-product-tool/node_modules/@babel/runtime/helpers/interopRequireDefault");

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _toast = _interopRequireDefault(_toast);
  _defineProperty2 = _interopRequireDefault(_defineProperty2);
  _actionSheet = _interopRequireDefault(_actionSheet);
  _icon = _interopRequireDefault(_icon);
  _imagePreview = _interopRequireDefault(_imagePreview);
  _uploader = _interopRequireDefault(_uploader);
  _field = _interopRequireDefault(_field);

  var _components;

  var _default = {
    name: "RyAddUploadImgPage",
    components: (_components = {
      Field: _field.default,
      Uploader: _uploader.default
    }, (0, _defineProperty2.default)(_components, _imagePreview.default.Component.name, _imagePreview.default.Component), (0, _defineProperty2.default)(_components, "Icon", _icon.default), (0, _defineProperty2.default)(_components, "ActionSheet", _actionSheet.default), _components),
    data: function data() {
      var ossHost = this.$config.base.ossHost;
      return {
        ossHost: ossHost,
        num: '',
        fileList: [],
        tagList: [],
        isShow: false,
        tagItem: {}
      };
    },
    created: function created() {
      var vm = this; // const { USER_NAME } = vm.$config.keys;
      // let name = vm.$localStorage.getItem(USER_NAME);

      var uuid = vm.$localStorage.getItem('uuid') || vm.$route.query.uuid;
      var fType = vm.$strTool.filterFactoryType(uuid);

      if (fType != 'yingwo') {
        vm.$router.replace({
          path: "404"
        });
        return;
      }

      vm.loadTag(true);
    },
    computed: {
      isPass: function isPass() {
        return Number(this.num) < 1000 && this.fileList.length > 0 && this.tagItem.id;
      }
    },
    methods: {
      initData: function initData() {
        this.num = '';
        this.fileList = [];
        this.tagItem = {};
        this.isShow = false;
      },
      loadList: function loadList() {
        var vm = this;
        var params = {
          paeNo: vm.pageNo,
          pageSize: 20
        };
        vm.loading = true;
        vm.$http({
          type: "get",
          url: "".concat(vm.$config.base.PROBE_BASE_URL, "scrapProbe/list"),
          params: params,
          except: true
        }).then(function (res) {
          if (!res.data) {
            (0, _toast.default)("网络错误");
            return;
          }

          vm.loading = false;
          res.data.scrapProbList.data.map(function (item) {
            item.time = (0, _time.getYMD)(item.uploadAt);
          });
          vm.list = vm.list.concat(res.data.scrapProbList.data);
          vm.isShowAll = res.data.scrapProbList.data.length < 20;
        });
      },
      afterRead: function afterRead(file) {
        var vm = this;
        file.status = 'uploading';
        file.message = '上传中...';
        (0, _compressImage.compressImage)(file.content, {
          width: 500,
          quality: 0.1
        }).then(function (res) {
          // vm.uploadImg(convertBase64UrlToBlob(res), file);
          vm.uploadImg(res, file);
        }); // vm.uploadImg(file)
      },
      uploadImg: function uploadImg(file, upFile) {
        var vm = this; // const f = new FormData();
        // f.append('file', file.file)
        // alert(JSON.stringify(file))

        vm.$http({
          type: "post",
          url: "".concat(vm.$config.base.ADMIN_URL, "javaApi/file/iot/qcImages?password=").concat(vm.$config.base.password),
          // data: { file: f }
          data: {
            encodeFile: file.split('base64,')[1],
            ext: file.split(';base64')[0].split('image/')[1]
          }
        }).then(function (res) {
          upFile.url = res.data;
          upFile.status = 'succ';
          upFile.message = '';
        }).catch(function (err) {
          console.log(err);
        });
      },
      previewImg: function previewImg(index) {
        var vm = this;
        (0, _imagePreview.default)({
          images: vm.info.images,
          startPosition: index
        });
      },
      loadTag: function loadTag(isResult) {
        var vm = this;
        var params = {};
        !isResult ? params.type = 6 : params.handlingType = 2;
        vm.$http({
          type: "get",
          url: "".concat(vm.$config.base.PROBE_BASE_URL).concat(isResult ? 'fc/careInfo/tags' : 'returnFac/tags'),
          params: params
        }).then(function (res) {
          res.data.map(function (item) {
            item.name = item.tagName;
          });
          vm.tagList = res.data;
        });
      },
      selectWarning: function selectWarning(obj) {
        this.tagItem = obj;
      },
      submit: function submit() {
        var vm = this;

        if (!vm.isPass) {
          var err = '';
          !vm.tagItem.id && (err = '请选择故障类型');
          !vm.fileList.length && (err = '请上传图片');
          !(Number(vm.num) < 1000) && (err = '请输入数量(1000以下)');
          err && (0, _toast.default)(err);
          return;
        }

        var images = [];
        vm.fileList.map(function (item) {
          images.push(item.url);
        });
        vm.ttid = _toast.default.loading({
          message: "加载中...",
          forbidClick: true,
          overlay: true,
          duration: 0
        });
        vm.$http({
          type: "POST",
          url: "".concat(vm.$config.base.PROBE_BASE_URL, "scrapProbe"),
          data: {
            size: vm.num,
            tagId: vm.tagItem.id,
            images: images,
            password: vm.$config.base.password
          }
        }).then(function (res) {
          vm.ttid && vm.ttid.clear();

          if (res.code != 1) {
            _toast.default.fail(res.message || '创建失败');

            return;
          }

          _toast.default.success({
            message: '创建成功'
          });

          vm.initData();
          vm.$router.back();
        }).catch(function (err) {
          vm.loading = false;
          vm.ttid && vm.ttid.clear();
          console.log(err);
        });
      },
      back: function back() {
        this.$router.back();
      }
    }
  };
  _exports.default = _default;
});